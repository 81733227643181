#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
}

.skillTitle {
    font-size: 3rem;
    font-weight:  600;
    padding-top: 0rem;
    margin-bottom: 1.5rem;
}

.skillDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 60rem;
}

.skillBars {
    margin: 1.5rem;
    width: 100vw;
    max-width: 84%;
    text-align: left;
}

.skillBar {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: .5rem;
    background: rgb(50, 50, 50);
}

.skillBarImg {
    object-fit: cover;
    height: 4rem;
    width: 4rem;
    margin-right: 2rem;
}
.skillBarText > p {
    font-size: .8rem;
    font-weight: 200;
}

@media screen and (max-width: 700px) {
    .skillBarText > p {
        font-size: 2.5vw;
    }
    .skillBarImg {
        height: 3rem;
        width: 3rem;
    }
    .skillBarText >h2 {
        font-size:  5vw;
    }
}

.skillText {
    display: flex;
    flex-direction: column;
    justify-content: center;
}