.navbar{
    background: rgb(40, 40, 40);
    height: 5rem;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo{
    width: 5rem;
    object-fit: cover;
    /* height: 2.5rem; */
    width: 3rem;
    margin-top: .08rem;
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover {
    color: gold;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid gold;
}

.desktopMenuBtn {
    background: white;
    color:black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .7rem 1rem;
    border-radius: 2rem;
    width: 10rem;
}

/* .a span {
    position:relative;
    display: block;
    background: gold;
}

.a span:nth-child(1) {
    left: 0;
    top: 0;
    width: 50.5%;
    height: 1.5px;
    transform: scaleX(0);
    transition: transform .5s;
}

.a:hover span:nth-child(1) {
    transform: scaleX(1);
} */

.desktopMenuBtn:hover {
    border-bottom: 3px solid gold;
    padding-bottom: -1rem;
    transform: .5s;
    cursor: pointer;
}

.active {
    color: gold;
    padding-bottom: .5rem;
    border-bottom: 3px solid gold;
}

.navMenu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40,40,40);
    border-radius: 1rem;
}

.mobMenu {
    width: 3rem;
    display: none;
    object-fit: cover;
}

.listItem {
    color: white;
    padding: .5rem 3rem;
    background: rgb(38, 38, 38);
}

@media screen and (max-width:720px){
    .navbar {
        margin-bottom: -2.5rem;
    }
    .mobMenu {
        display: flex;
    }
    .desktopMenu {
        display: none;
    }
    .desktopMenuBtn {
        display: none;
    }
}