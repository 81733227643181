#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

:root {
    --max-width: 700px; /* Maximum width for full brightness */
    --min-width: 500px; /* Minimum width for minimum brightness */
}

.portrait {
    position: absolute;
    top: 10rem;
    right: 6rem;
    z-index: -1;
    object-fit: cover;
    height: 25rem;
    border-radius: 7%;
    filter: brightness(100%);
    transition: filter .5s ease; /* Add a smooth transition effect */

    /* Add media queries to adjust brightness based on screen width */
    @media screen and (max-width: 900px) {
        filter: brightness(65%);
    }

    @media screen and (max-width: 700px) {
        filter: brightness(50%);
    }

    @media screen and (max-width: 550px) {
        filter: brightness(45%);
    }

    @media screen and (max-width: 400px) {
        filter: brightness(40%);
    }
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 4rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hello {
    font-size: 1.75rem;
    font-weight: 100;
    padding-bottom: .5rem;
}

.introName {
    color: gold;
}

p {
    padding-top: .1rem;
    font-size: medium;
    font-weight: 300;
}

.btn {
    background: white;
    margin: 1rem 0;
    padding: .75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
}

.btn:hover {
    cursor: pointer;
    border-bottom: 3px solid gold;
    padding-bottom: -1rem;
    transform: .5s;
}

.hirebtn {
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
    width: 1.2rem;
    
}

@media screen and (max-width:840px){
    .portrait {
        right: 10vw;
        height: 23rem;
    }
    .introContent {
        margin-top: -1rem;
        right: -30vw;
        font-size: 7.5vw;
    }
    .hello {
        font-size: 4.5vw;
    }
}
@media screen and (max-width:600px){
    .portrait {
        height: 22rem;
        right: 21vw;
    }
}
@media screen and (max-width:550px){
    .hello {
        font-size: 4.5vw;
        margin-top: 4rem;
    }
    .portrait {
        height: 22rem;
    }
    
}
@media screen and (max-width: 500px) {
    .hello {
        font-size: 6vw;
        text-align: left;
    }

    .introContent {
        right: auto;
        left: 50%;
        transform: translateX(5%);
        font-size: 9vw;
    }

    .portrait {
        margin: 0 auto 5rem;
        margin-left: 6rem;
        right: 23vw;
        height: 21rem;
    }

    .introIcons {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        transform: translateX(-33%);
    }

    .btn {
        margin: 0 auto 5rem; /* Center horizontally, add space below */
        text-align: center;
        transform: translateX(33%);
    }
}


.links {
    display: flex;
    flex-direction: column;
}

.introLink {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-bottom: -1.3rem;
}

.introBtn {
    margin-top: -5rem;
}

.link:hover {
    cursor: pointer;
}

/* a {
    position: relative;
    display: inline-block;
    transition: .5s;
}


a span {
    position: absolute;
    display: block;
    background: gold;
}

a span:nth-child(1) {
    left: 0;
    top: 0;
    width: 50.5%;
    height: 1.5px;
    transform: scaleX(0);
    transition: transform .5s;
} */

