#projects {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.projectsTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.projectsDesc {
    font-weight: 300;
    font-size: 1rem;
}

.projectBtn {
    padding: 0.5rem 2.5rem;
    background: white;
    border-radius: 2rem;
    border: none;
    font-size: 1rem;
    margin: 3rem 0;
}

.cardContainer {
    max-width: 55rem;
    border-radius:  1rem;
    box-shadow: 0px 10px 8px #202020;
    display: flex;
    flex-direction: row;
    margin: 1rem;
    background-color: rgb(50, 50, 50);
    height: 27rem;
}

.projectImg {
    border-radius: .5rem;
    object-fit: cover;
    box-shadow: 0px 10px 8px #202020;
    flex: 1;
    margin: 1.5rem;
    max-width: 53%;
}

.cardDesc {
    flex: 1;
    max-width: 49%;
}

.cardDesc > h3 {
    margin-top: 2.22rem;
}

.cardDesc > p {
    margin: 1.rem;    
    font-size: .85rem;
    margin: .5rem;
}

h3 {
    font-weight: bold;
}

.software {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.software > p {
    align-items: center;
    border-radius: .5rem;
    padding: .5rem;
    box-shadow: 2px 2px 8px #202020;
    font-weight: bold;
    margin: .2rem;
    font-size: .7rem;
}

@media screen and (max-width:888px) {
    .cardDesc > p {
        font-size: .85rem;
        margin: .5rem;
    }
    .software > p {
        font-size: .7rem;
        margin-left: 0;
        margin-right: 0;
    }
    .projectTitle {
        font-size: 1rem;
    }
    .cardContainer {
        height: 30rem;
    }
}

@media screen and (max-width:750px) {
    .software > p {
        font-size: .53rem;
        margin-left: 0;
        margin-right: 0;
    }
    .cardDesc > p {
        font-size: .8rem;
        margin: .6rem;
    }
}

@media screen and (max-width:700px) {
    .cardContainer {
        flex-direction: column;
        height: 36rem;
    }
    .projectImg, .cardDesc{
        max-width: 100%;
        max-height: 14rem;
        margin: .8rem;
    }
    .cardDesc > h3 {
        margin-top: .7rem;
    }
    .cardDesc > p {
        margin: .5rem;
        font-size: .75rem
    }
    .software > p {
        font-size: .7rem;
        margin: .5rem;
        margin-bottom: .2rem;
    }
}

@media screen and (min-width: 701px) {
    .cardContainer:nth-child(even) {
    
        flex-direction: row-reverse; /* Reverse the layout for even cardContainers */
    }
    h2 {
        margin: 0;
    }
}

.codeLinks {
    margin-top: 1rem;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.codeLinks > a {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1rem;

}
.codeLinks > a > p {
    margin-right: 0.2rem; /* Add margin to the right of the paragraph */
}
.code {
    margin-bottom: 0rem;
    filter: invert(100%);
    width: 1.1rem;
}

.codeLinks > a:hover p,
.codelinks > a:hover img {
  color: gold; /* Change the text color to gold on hover */
}

.codeLinks a:hover img {
    filter: invert(84%) sepia(38%) saturate(2483%) hue-rotate(358deg) brightness(104%) contrast(106%);
}

.link {
    color: white;
    width: 1.2rem;
    height: 1.2rem;
}